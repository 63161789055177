import axios from 'axios';

import { app, auth, provider } from '../firebaseConfig'


//DATABASE API
export async function insertUser() {

    var res = null;

    if (auth.currentUser) {

        var token = await auth.currentUser.getIdToken();
        //var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }
        var config = { headers: { 'Content-type': 'multipart/form-data' } }

        var params = {
            uid: auth.currentUser.uid,
            token
        }

        console.log('insertUser params, config', params, config);

        await axios
            .post(
                "https://karaokeflix.nodespace.io/api/add.user.php",
                params,
                config
            )
            .then((response) => {
                console.log('insertUser response', response);
                res = response.data

            })
            .catch((error) => {
                console.log("error", error)
            });

    }

    return res

}



export async function logSignIn() {

    var result = false;


    if (auth.currentUser) {

        var token = await auth.currentUser.getIdToken();

        var params = {
            token,
            uid: auth.currentUser.uid,
        }

        //var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }
        var config = { headers: { 'Content-type': 'multipart/form-data' } }


        console.log('logSignIn', params);


        result = await axios
            .post(
                "https://karaokeflix.nodespace.io/api/log.signin.php",
                params,
                config
            )
            .then((response) => {

                
                console.log('logSignIn response --->>> ', response);

                result = response.data

            })
            .catch((error) => {
                console.log("logSignIn error", error)
            });



    }

    return result;

}





export async function getUser() {

    var data = null



    if (auth.currentUser) {

        var token = await auth.currentUser.getIdToken();

        var params = {
            token,
            uid: auth.currentUser.uid,
            user_args: { user_uid: auth.currentUser.uid },
            meta_args: {}
        }




        //var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }
        var config = { headers: { 'Content-type': 'multipart/form-data' } }


        console.log('getUser', params);


        await axios
            .post(
                "https://karaokeflix.nodespace.io/api/get.users.php",
                params,
                config
            )
            .then((response) => {

                /* if (response.data.length > 0) {
                    data = response.data[0]
                } */
                console.log('getUser response --->>> ', response);



                data = response.data[0]

            })
            .catch((error) => {

                console.log("getUser error", error)
            });

    }

    console.log('getUser data --->>> ', data);


    return data
}





export async function updateUser(user) {

    var res = null;

    if (auth.currentUser && displayName) {

        var token = await auth.currentUser.getIdToken();



        var params = {
            token,
            uid: auth.currentUser.uid,
            user: user
        }

        console.log('updateUser', params);

        //var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }
        var config = { headers: { 'Content-type': 'multipart/form-data' } }

        await axios
            .post(
                "https://karaokeflix.nodespace.io/api/update.user.php",
                params,
                config
            )
            .then((response) => {
                console.log('update.user response', response);
                res = response.data
            })
            .catch((error) => {
                console.log("update.user error", error)
            });


    }






    return res

}