<template>
  
  <div>
   <div class="page-wrapper compact-wrapper modern-type" id="pageWrapper" :class="layoutobj"
      :style="{ position: this.$route.path === '/player' ? 'static' : 'relative' }">
      <div class="page-header close_icon" :class="{ close_icon: !togglesidebar }">
        <Header @clicked="sidebar_toggle" />
      </div>

      <div class="page-body-wrapper">
        <!-- <div class="bg-overlay" :class="{active: activeoverlay }" @click="removeoverlay()"></div> -->
        <div class="page-body" 
          :style="{ position: this.$route.path === '/player' ? 'static' : 'relative' }">
          <transition name="fadeIn" enter-active-class="animated fadeIn">
            <router-view class="view"></router-view>
          </transition>
        </div>
        <!-- <Footer /> -->
      </div>
      <!-- <Customizer/> -->
      <TapTop />
    </div>

  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { layoutClasses } from '../constants/layout';
import Header from './header';
import Footer from './footer.vue';
// import Customizer from './customizer';
import TapTop from './tapTop.vue';

export default {
  //   name: 'mainPage',
  components: {
    Header,
    Footer,
    // Customizer,
    TapTop
  },
  data() {
    return {
      loading: true,
      mobileheader_toggle_var: false,
      resized: false,
      layoutobj: {}
    };
  },
  computed: {
    ...mapState({
      menuItems: state => state.menu.data,
      layout: state => state.layout.layout,
      activeoverlay: (state) => state.menu.activeoverlay,
      svg: (state) => state.layout.svg
    }),
    ...mapGetters({
    }),
    layoutobject: {
      get: function () {
        // return 'hello'
        return JSON.parse(JSON.stringify(layoutClasses.find((item) => Object.keys(item).pop() === this.layout.settings.layout)))[this.layout.settings.layout];
      },
      set: function () {
        this.layoutobj = layoutClasses.find((item) => Object.keys(item).pop() === this.layout.settings.layout);
        this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[this.layout.settings.layout];
        return this.layoutobj;
      }
    }
  },
  watch: {
    '$route'() {
      setTimeout(() => {
        this.loading = !this.loading
      }, 3000)
      this.loading = !this.loading
      this.menuItems.filter(items => {
        if (items.path === this.$route.path)
          this.$store.dispatch('menu/setActiveRoute', items);
        if (!items.children) return false;
        items.children.filter(subItems => {
          if (subItems.path === this.$route.path)
            this.$store.dispatch('menu/setActiveRoute', subItems);
          if (!subItems.children) return false;
          subItems.children.filter(subSubItems => {
            if (subSubItems.path === this.$route.path)
              this.$store.dispatch('menu/setActiveRoute', subSubItems);
          });
        });
      });
      this.layoutobj = layoutClasses.find((item) => Object.keys(item).pop() === this.layout.settings.layout);
      console.log("layobj==>", this.layoutobj)

      if ((window.innerWidth < 991 && this.layout.settings.layout === 'LosAngeles') || (window.innerWidth < 991 && this.layout.settings.layout === 'Singapore') || (window.innerWidth < 991 && this.layout.settings.layout === 'Barcelona')) {
        const newlayout = JSON.parse(JSON.stringify(this.layoutobj).replace('horizontal-wrapper', 'compact-wrapper'));
        // console.log("newlayobj==>",newlayout)

        this.layoutobj = JSON.parse(JSON.stringify(newlayout))[this.layout.settings.layout];
      } else {
        this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[this.layout.settings.layout];
      }
    },
    
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.$store.dispatch('layout/set');
    // this.$router.replace({ 'query': null }).catch(err => err);
    this.layout.settings.layout = this.$route.query.layout ? this.$route.query.layout : 'Seoul';
    this.layoutobj = layoutClasses.find((item) => Object.keys(item).pop() === this.layout.settings.layout);
    this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[this.layout.settings.layout];

  },
  methods: {
    
    mobiletoggle_toggle(value) {
      this.mobileheader_toggle_var = value;
    },
    handleResize() {
      this.$store.dispatch('menu/resizetoggle');
    },
    removeoverlay() {
      this.$store.state.menu.activeoverlay = false;
      
      this.menuItems.filter(menuItem => {
        menuItem.active = false;
      });
    },
    
  },
  mounted() {
    // setTimeout(()=>{
    this.loading = false;

    // },2000)

  }
};
</script>

<style>
.page-header {
  background-color: rgba(0, 0, 0, 0) !important;
  position: relative !important;
  box-shadow: none !important;
}

.page-body {
  margin-top: 0 !important;
  padding: 0 !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

.page-body>.container-fluid {
  max-width: unset !important;
}

.page-body-wrapper {
  background-color: rgba(0, 0, 0, 0) !important;
}

.page-wrapper {
  background: linear-gradient(90deg, #060606, #111111) !important;
  background-attachment: fixed !important;
}


.page-wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(90deg, rgba(3, 3, 3, 1), rgba(3, 3, 3, 1), rgba(3, 3, 3, 1), rgba(3, 3, 3, .75), rgba(3, 3, 3, 0)) !important;
  /* Cor de fundo do pseudo elemento, ajuste conforme necessário */
  transition: all .3s ease;
  /* Adiciona uma transição para suavizar a mudança de visibilidade */
  z-index: 2;
}





/* Para navegadores WebKit (Chrome, Safari) */
::-webkit-scrollbar {
  display: none !important;
}


/* Para navegadores Gecko (Firefox) */
::-moz-scrollbar {
  display: none !important;
}
</style>