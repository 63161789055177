import axios from 'axios';

import { app, auth, provider } from '../firebaseConfig'


//DATABASE API
export async function insertOrder(plan, gateway) {

    var res = null;

    if (auth.currentUser) {

        var token = await auth.currentUser.getIdToken();
        //var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }
        var config = { headers: { 'Content-type': 'multipart/form-data' } }

        var params = {
            uid: auth.currentUser.uid,
            token,
            gateway,
            plan,
            apptoken: auth.currentUser.uid + '-' + new Date().getTime()
        }

        console.log('params, config', params, config);

        await axios
            .post(
                "https://karaokeflix.nodespace.io/api/karaokeflix/add.order.php",
                params,
                config
            )
            .then((response) => {
                console.log('response', response);
                res = response.data

            })
            .catch((error) => {
                console.log("error", error)
            });

    }
    return res

}



export async function getOrders() {

    var data = null



    if (auth.currentUser) {

        var token = await auth.currentUser.getIdToken();

        var params = {
            token,
            uid: auth.currentUser.uid,
            post_args: {},
            meta_args: { user_uid: auth.currentUser.uid }
        }




        //var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }
        var config = { headers: { 'Content-type': 'multipart/form-data' } }


        console.log('getUser', params);


        await axios
            .post(
                "https://karaokeflix.nodespace.io/api/get.users.php",
                params,
                config
            )
            .then((response) => {

                /* if (response.data.length > 0) {
                    data = response.data[0]
                } */
                console.log('getUser response --->>> ', response);



                data = response.data[0]

            })
            .catch((error) => {

                console.log("getUser error", error)
            });

    }

    console.log('getUser data --->>> ', data);


    return data
}



