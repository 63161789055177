import axios from 'axios';

export async function getSonglist() {

    var songlist = null

    var params = {}
    var config = {}

    await axios
        .get(
            "https://karaokeflix.nodespace.io/songlist/?origin=webapp",
            params,
            config
        )
        .then((response) => {
            console.log("response", response)
            songlist = response.data
        })
        .catch((error) => {
            console.log("error", error)
        });

    return songlist

}



export function queryArtistPhoto(artist) {

    artist = artist.toString().trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, "-").replace(/[^\w]/g, "-");
  
    let imageUrl = 'https://vagalume.com.br/' + artist + "/images/" + artist + ".jpg";

    //console.log(imageUrl, 'queryArtistPhoto');

    return imageUrl;
  
  }










