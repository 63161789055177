<template>
  <div class="container-fluid my-10" style="min-height: 85vh;">



    <div class="row justify-content-center">

      <h1 class="text-center mb-10">Contato</h1>


      <div class="card col-md-6" style="min-height: 50vh;">

        <div class="card-body">


          <div class="row py-10">

            <div class="col-6 text-center">

              <h4>Atendimento</h4>
              <p class="f-w-700 font-primary"><i class="icofont icofont-clock-time"></i> Todos os dias das 9h às 21h</p>

              <h4>Telefones</h4>
              <p class="f-w-700"><a href="tel:11993389339" class="font-primary"> <i class="icofont icofont-phone"></i>
                  (11) 99338-9339</a></p>

              <h4>WhatsApp</h4>
              <p class="f-w-700"><a class="font-primary"
                  href="https://api.whatsapp.com/send/?phone=5511993389339&amp;text&amp;type=phone_number&amp;app_absent=0"
                  target="_blank" rel="noopener"><i class="icofont icofont-brand-whatsapp"></i> (11) 99338-9339</a></p>

              <h4>Social</h4>
              <p class="f-w-700 font-primary"><i class="icofont icofont-social-facebook f-26 mx-1"></i> <i
                  class="icofont icofont-social-instagram f-26 mx-1"></i></p>

            </div>

            <div class="col-6">
              <div class="tab-content" id="v-pills-tabContent">
                <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                  aria-labelledby="v-pills-home-tab">


                  <form class="form theme-form font-light" @submit.prevent="submitContact">

                    <div class="row">
                      <div class="col-12">
                        <div class="mb-3">
                          <label class="form-label" for="displayName">Nome *</label>
                          <input class="form-control" id="displayName" type="text" placeholder="Nome"
                            v-model="userData['displayName']">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="mb-3">
                          <label class="form-label" for="email">Email *</label>
                          <input class="form-control" id="email" type="email" placeholder="nome@exemplo.com"
                            v-model="userData['email']">
                        </div>
                      </div>
                    </div>


                    <div class="row">
                      <div class="col">
                        <div class="mb-3">
                          <label class="form-label" for="phone">Mensagem *</label>
                          <textarea class="form-control" id="phone" placeholder="Mensagem" v-model="message"></textarea>

                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <div class="mt-3">
                          <button class="btn btn-primary m-r-10 font-light" type="submit">Enviar Mensagem</button>

                        </div>
                      </div>
                    </div>





                  </form>



                </div>
              </div>
            </div>
          </div>




        </div>


      </div>

    </div>

  </div>




  <Footer />
</template>


<script>
import { mapState } from 'vuex';


import 'vue3-carousel/dist/carousel.css'
import Footer from '../components/footer.vue';
import { getUser } from '../data/users';
import { app, auth, provider } from '../firebaseConfig'

import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: 'bottom' });


export default {

  components: {
    Footer,
  },
  data() {
    return {
      userData: { displayName: null, email: null },
      loaded: false,
      breakpoints: {
        700: {
          itemsToShow: 3.5,
          itemsToScroll: 1,
          snapAlign: 'center',
        },
        1024: {
          itemsToShow: 7.5,
          itemsToScroll: 3,
          snapAlign: 'start',
        },
      },
    };
  },
  async mounted() {

    auth.onAuthStateChanged(async user => {
      if (user) {
        this.userData = await getUser();
      }
      else {
        this.userData = { displayName: null, email: null }
      }
    });



  },
  computed: {

  },
  methods: {



    async submitContact() {

      toaster.error(`Oops! Email de destino não definido.`)


    }


  },
  setup() {
    return {
      modules: [],
    };
  },
};
</script>

