<template>
  <p class="text-center f-12">Sua playlist tem {{ playlist.length }} músicas</p>


  <div class="pt-1 pb-1" v-for="(song, index) in playlist" :key="'playlist-' + index" v-if="playlist.length > 0">


    <div style="display: flex; flex-direction: row;" v-if="playlist[0] !== null">


      <div style="flex: .2">
        <div class="img-wrapper">
          <div class="img-container"
            :style="{ backgroundImage: `url(${this.getArtistPhoto(song[0])}), url(${require('../assets/images/placeholder-artist.jpg')})` }">
          </div>
        </div>
      </div>

      <div class="px-2" style="flex: 1">
        <h6 class="f-14 m-0" :class="{ 'playing': currentSong && currentSong[1] === song[1] }">{{ song[2] }}</h6>
        <span class="f-12 m-0" :class="{ 'playing': currentSong && currentSong[1] === song[1] }">{{ song[0] }}</span>
      </div>

      <div style="flex: .2; ">
        <div style="position: relative; display: flex; flex-direction: row; justify-content: flex-end;">
          <div class="remove-button" title="Remover da playlist"
            @click="this.$store.dispatch('removeFromPlaylist', song);">
            <i class="fa fa-trash"></i>
          </div>

          
          <div v-if="this.mode !== 'karaoke'" class="play-button" title="Reproduzir" @click="setCurrentSong(song)">
            <i class="fa fa-play"></i>
          </div>
        </div>
      </div>


    </div>




  </div>
</template>
<script>
import { mapState } from 'vuex';
import { queryArtistPhoto } from '../data/songlist';


export default {
  //props: ['activeclass'],
  data() {
    return {

    };
  },
  props: {
    mode: {
      type: String,
      required: false,
    }
  },
  computed: {
    ...mapState({
      playlist: (state) => state.playlist,
      currentSong: (state) => state.currentSong,
      credits: (state) => state.credits,
      creditsMode: (state) => state.creditsMode,
    }),
  },
  watch: {
    currentSong(newValue, oldValue) {

    }
  },
  mounted() {

  },
  methods: {
    getArtistPhoto(artist) {
      return artist ? queryArtistPhoto(artist) : '';
    },
    setCurrentSong(song) {

      this.$store.dispatch('setPlayStatus', false);


      console.log('setCurrentSong', this.creditsMode, this.credits, this.$route.path)

      if (!this.creditsMode || (this.creditsMode && this.credits > 0) || this.$route.path !== '/karaoke') {

        this.$store.dispatch('setCurrentSong', { song });
        this.$store.dispatch('setPlayStatus', true);


        if (this.$route.path !== '/player' && this.$route.path !== '/karaoke') {
          console.log('redirect', this.$route.path);
          this.$router.push({ name: 'player' })
        }


      } else {


        this.$swal({
          icon: "info",
          title: "Créditos",
          text: "Para continuar cantando, insira mais créditos",
          type: "info",
        });
        
      
      }





    }
  },
};
</script>
<style>
.playlist-wrapper .img-wrapper {
  overflow: hidden;
  border-radius: 8px;
}

.playlist-wrapper .img-container {
  max-width: 35px;
  border-radius: 8px;
  aspect-ratio: 1/1;
  background-position: center center;
  overflow: hidden;
  transition: transform .5s;
  -webkit-transition: -webkit-transform .5s
}



.playlist-wrapper .play-button {
  background-color: rgba(50, 150, 100, 1);
  width: 25px;
  aspect-ratio: 1/1;
  line-height: 25px;
  border-radius: 50px;
  font-size: 14px;
  color: #eee;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}

.playlist-wrapper .play-button:hover {
  background-color: rgba(40, 140, 90, 1);
}



.playlist-wrapper .remove-button {
  background-color: rgb(235, 235, 235);
  width: 25px;
  aspect-ratio: 1/1;
  line-height: 25px;
  border-radius: 50px;
  font-size: 14px;
  color: #333 !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}

.playlist-wrapper .remove-button:hover {
  background-color: rgb(225, 225, 225);
}

.playlist-wrapper .remove-button i {
  color: #333 !important;
}

.playing {
  color: rgba(50, 150, 100, 1);

}
</style>