<template>
  <div class="container-fluid">
    <div class="row starter-main left-spaced p-r-50" style="min-height: 85vh;">
      <div class="col-md-12 p-0">

        <h1 class="text-center mb-10">Pesquisa</h1>

        <Search />

      </div>
    </div>
  </div>
  <Footer />
</template>


<script>
import { mapState } from 'vuex';


import 'vue3-carousel/dist/carousel.css'
import Footer from '../components/footer.vue';
import { getUser } from '../data/users';
import Search from '../components/search';
import { app, auth, provider } from '../firebaseConfig'

import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: 'bottom' });


export default {

  components: {
    Search,
    Footer,
  },
  data() {
    return {
      plans: [
        {
          name: 'diario',
          title: "Plano Diário",
          price: 50,
          duration: '/ dia',
          list: [{ content: "24 horas de acesso" },
          { content: "+17 mil músicas completas" },
          { content: "Acesso à Máquina Karaokê" },
          { content: "1 Karaokê por vez *" },
          { content: "Ideal para eventos" }],
        },
        {
          name: 'mensal',
          title: "Plano Mensal",
          price: 250,
          duration: '/ mês',
          list: [{ content: "30 dias de acesso" },
          { content: "+17 mil músicas completas" },
          { content: "Acesso à Máquina Karaokê" },
          { content: "1 Karaokê por vez *" },
          { content: "Ideal para bares" }],
        },
        {
          name: 'mensal-pro',
          title: "Plano Mensal - PRO",
          price: 500,
          duration: '/ mês',
          list: [{ content: "30 dias de acesso" },
          { content: "+17 mil músicas completas" },
          { content: "Acesso à Máquina Karaokê" },
          { content: "Até 5 Karaokês simultâneos" },
          { content: "Ideal para franquias" }],
        }
      ],
      loaded: false,
      breakpoints: {
        700: {
          itemsToShow: 3.5,
          itemsToScroll: 1,
          snapAlign: 'center',
        },
        1024: {
          itemsToShow: 7.5,
          itemsToScroll: 3,
          snapAlign: 'start',
        },
      },
    };
  },
  async mounted() {

    auth.onAuthStateChanged(async user => {

      console.log('user', await getUser())

    });



  },
  computed: {

  },
  methods: {

    async orderPlan(plan) {



      if (auth.currentUser && auth.currentUser.uid && (!auth.currentUser.email || (auth.currentUser.email && auth.currentUser.emailVerified))) {

        var payRedirect = await insertOrder(plan, 'mercadopago');
        if (payRedirect) {
          window.location.href = payRedirect;
        }

      }
      else {
        this.$router.push(
          { name: 'login' }
        )
      }

    }


  },
  setup() {
    return {
      modules: [],
    };
  },
};
</script>


<style>
.left-spaced {
  margin-left: 100px !important;
}

.left-padded {
  padding-left: 100px !important;
}
</style>