<template>
  <div class="keyboard">
    <button @click="handleKeyClick(' ')" class="special-keys space-key">
      _
    </button>
    <button @click="handleKeyClick('Backspace')" class="special-keys backspace-key">
      <vue-feather type="delete"></vue-feather>
    </button>
    <button @click="handleKeyClick('Clear')" class="special-keys clear-key">
      <vue-feather type="x"></vue-feather>
    </button>
  </div>

  <div class="keyboard">
    <button v-for="key in keyboardChars" :key="key" @click="handleKeyClick(key)">
      {{ key }}
    </button>
    <button v-for="key in keyboardNumbers" :key="key" @click="handleKeyClick(key)">
      {{ key }}
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      keyboardNumbers: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0',],
      keyboardChars: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
    };
  },
  methods: {
    handleKeyClick(key) {
      this.$emit('key-click', key);
    },
  },
};
</script>

<style scoped>
.keyboard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 0;
}

.keyboard button {
  font-size: 22px;
  cursor: pointer;
  background-color: rgba(12, 12, 15, .5);
  border-radius: 8px;
  height: 50px;

  flex: 0 0 calc(14%);
  /* Ajuste para 6 elementos por linha */
  max-width: calc(14%);
  box-sizing: border-box;
  margin: 5px;
}

.keyboard button.special-keys {
  flex: 0 0 calc(30%);
  max-width: calc(30%);
}

.keyboard button:hover, .keyboard button:active, .keyboard button:focus {
  outline: 3px solid #ec1c24;
}
</style>