<template>
  <router-link to="/">
    <img
      class="img-fluid for-dark"
      src="../../assets/images/logo/logo-simple-red-white.png"
      alt=""
    />
  </router-link>
</template>
<script>

  export default {
    name: 'Logo',
  };
</script>
<style>
.logo-wrapper {
  box-shadow: none !important;
}
</style>