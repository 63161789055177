<template>
  <div class="container-fluid my-10" style="min-height: 85vh;">


    <div class="row justify-content-center">

      <div class="card col-md-6" style="min-height: 50vh;">
        <div class="card-header pb-0">
          <h3>Minha Conta</h3>
        </div>

        <div class="card-body">


          <div class="row">
            <div class="col-sm-3 tabs-responsive-side line-tab-left">
              <div class="nav flex-column nav-pills border-tab nav-left" id="v-pills-tab" role="tablist"
                aria-orientation="vertical"><a class="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill"
                  href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">Meus Dados</a><a
                  class="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" href="#v-pills-profile" role="tab"
                  aria-controls="v-pills-profile" aria-selected="false">Meus Pedidos</a></div>
            </div>
            <div class="col-sm-9">
              <div class="tab-content" id="v-pills-tabContent">
                <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                  aria-labelledby="v-pills-home-tab">


                  <form class="form theme-form font-light" @submit.prevent="submitProfile">

                    <div class="row">
                      <div class="col-12">
                        <div class="mb-3">
                          <label class="form-label" for="displayName">Nome *</label>
                          <input class="form-control" id="displayName" type="text" placeholder="Nome"
                            v-model="userData['displayName']">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="mb-3">
                          <label class="form-label" for="email">Email *</label>
                          <input class="form-control bg-light" id="email" type="email" placeholder="nome@exemplo.com"
                            v-model="userData['email']" disabled>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="mb-3">
                          <label class="form-label" for="phone">Telefone</label>
                          <input class="form-control" id="phone" type="text" placeholder="Telefone"
                            v-model="userData['phoneNumber']">
                          <small>Ex.: +55 11 99999-9999</small>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <div class="mt-3">
                          <button class="btn btn-primary m-r-10 font-light" type="submit">Salvar</button>

                        </div>
                      </div>
                    </div>





                  </form>



                </div>
                <div class="tab-pane fade font-light" id="v-pills-profile" role="tabpanel"
                  aria-labelledby="v-pills-profile-tab">
                  <p>Nenhum pedido registrado até o momento</p>
                </div>
              </div>
            </div>
          </div>




        </div>


      </div>

    </div>

  </div>




  <Footer />
</template>


<script>
import { mapState } from 'vuex';


import 'vue3-carousel/dist/carousel.css'
import Footer from '../components/footer.vue';
import { getUser, updateUser } from '../data/users';
import { app, auth, provider } from '../firebaseConfig'

import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({ position: 'bottom' });


export default {

  components: {
    Footer,
  },
  data() {
    return {
      userData: {},
      loaded: false,
      breakpoints: {
        700: {
          itemsToShow: 3.5,
          itemsToScroll: 1,
          snapAlign: 'center',
        },
        1024: {
          itemsToShow: 7.5,
          itemsToScroll: 3,
          snapAlign: 'start',
        },
      },
    };
  },
  async mounted() {

    auth.onAuthStateChanged(async user => {

      if (user) {
        this.userData = await getUser();
      }
      else {
        this.$router.replace('/login');
      }

    });



  },
  computed: {

  },
  methods: {

    checkPhone(phone) {
      const regexTelefone = /^\+[0-9\s()-]{10,}$/
      return regexTelefone.test(phone);
    },


    async submitProfile() {

      if (this.userData['displayName'] && this.userData['email'] && (!this.userData['phoneNumber'] || this.checkPhone(this.userData['phoneNumber']))) {
        var result = await updateUser(this.userData)
        if (result > 0) {
          toaster.success(`Dados atualizados com sucesso!`)
        }
        else {
          toaster.error(`Oops! Não foi possível atualizar no momento. Verifique seus dados e tente novamente.`)
        }
      }
      else {
        toaster.warning(`Oops! Preencha os campos corretamente`)
      }

    }


  },
  setup() {
    return {
      modules: [],
    };
  },
};
</script>


<style></style>