
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCwMOr_7fsDHS_CT0j_Unr8wkKWIVrjSrU",
  authDomain: "karaokeflix-8c58c.firebaseapp.com",
  projectId: "karaokeflix-8c58c",
  storageBucket: "karaokeflix-8c58c.appspot.com",
  messagingSenderId: "87210789416",
  appId: "1:87210789416:web:4856cb34fbbb96c82d831d",
  measurementId: "G-QYSXZM1E8H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const provider = new GoogleAuthProvider();


export { app, auth, provider }