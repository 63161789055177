<template>
  <div style="margin-top: 50px">
    <footer class="footer" :class="this.footer == 'footer-dark' ? 'footer-dark' : ''">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 footer-copyright text-center">
            <p class="mb-0">Copyright 2023 © KaraokeFlix</p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import json from '../data/menu';
import { mapGetters } from 'vuex'
export default {
  name: 'footerpage',
  data() {
    return {
      customizer: false,
      data:
        'data:text/json;charset=utf-8,' +
        encodeURIComponent(JSON.stringify(json)),
    };
  },
  computed: {
    ...mapGetters({
      footer: 'layout/footer'
    })
  }
};
</script>
<style scoped>
footer {
  margin-left: 0 !important;
  margin-right: 0 !important;
  border-radius: 0 !important;
  background: rgba(12, 12, 15, 1) !important;
  border: none !important;
  box-shadow: none !important;
}
</style>