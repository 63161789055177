<template>
    <div class="songcard-wrapper" v-if="!compact" style="position: relative" :song="song[1]">


        <div class="add-button" title="Adicionar à playlist" @click="addToPlaylist()">
            <i class="fa fa-plus"></i>
        </div>

        <div class="play-button" title="Reproduzir" @click="redirectToPlayer()">
            <i class="fa fa-play"></i>
        </div>

        <div class="img-wrapper">
            <div class="img-container"
                :style="{ backgroundImage: `url(${this.getArtistPhoto(song[0])}), url(${require('../assets/images/placeholder-artist.jpg')})` }">
            </div>
        </div>

        <h6>{{ song[2] }}</h6>
        <p>{{ song[0] }} </p>
    </div>




    <div class="songcard-wrapper compact" v-if="compact"
        style="display: flex; flex-direction: row; justify-content: space-between;">



        <div style="display: flex; flex-direction: column; flex: .7">
            <h6>{{ song[2] }}</h6>
            <p>{{ song[0] }} </p>
        </div>

        <div style="position: relative; flex: .3">
            <div class="add-button" title="Adicionar à playlist" @click="addToPlaylist()">
                <i class="fa fa-plus"></i>
            </div>

            <div class="play-button" title="Reproduzir" @click="redirectToPlayer()">
                <i class="fa fa-play"></i>
            </div>
        </div>




    </div>
</template>
  
<script>

import { queryArtistPhoto } from '../data/songlist';


export default {
    name: 'songcard',
    data() {
        return {

        };
    },
    computed: {
    },
    props: {
        song: {
            type: Array,
            required: false,
        },
        compact: {
            type: Boolean,
            required: false,
        }
    },
    methods: {
        getArtistPhoto(artist) {
            return artist ? queryArtistPhoto(artist) : '';
        },
        addToPlaylist() {
            this.$store.dispatch('addToPlaylist', { song: this.song, messages: true });
        },
        redirectToPlayer() {
            this.$store.dispatch('addToPlaylist', { song: this.song, position: 0, messages: true });
            this.$store.dispatch('setCurrentSong', { song: this.song });
            this.$router.push({ name: 'player' })
        }
    }
};
</script>


<style>
.songcard-wrapper {
    min-height: 250px;
    min-width: 200px;
    width: 90%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: rgba(0, 0, 0, .33);
    border-radius: 8px;
    margin: 0 5px;
    transition: .25s;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, .75);

}

.songcard-wrapper:hover {
    background-color: rgba(0, 0, 0, .75);
}





.songcard-wrapper .img-wrapper {
    overflow: hidden;
    border-radius: 8px;
}

.songcard-wrapper .img-container {
    border-radius: 8px;
    aspect-ratio: 1/1;
    background-position: center center;
    /* background-image: url('../assets/images/placeholder-artist.jpg'); */
    overflow: hidden;
    transition: transform .5s;
    -webkit-transition: -webkit-transform .5s
}

.songcard-wrapper:hover .img-container {

    transform: scale(1.1);
    -webkit-transform: scale(1.1);

}

.songcard-wrapper h6 {
    margin: 10px 0 0 0;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
}

.songcard-wrapper p {
    margin: 0 0 0 0;
    font-size: 12px;
    font-weight: 300;
    text-align: left;
}

.songcard-wrapper .play-button {
    position: absolute;
    right: 20px;
    bottom: 0px;
    background-color: rgba(50, 150, 100, 1);
    width: 50px;
    aspect-ratio: 1/1;
    line-height: 50px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .25s;
    opacity: 0;
    font-size: 20px;
    color: #eee;
    cursor: pointer;
    z-index: 10;

}

.songcard-wrapper:hover .play-button {
    bottom: 50px;
    opacity: 1;
}



.songcard-wrapper .add-button {
    position: absolute;
    right: 75px;
    bottom: 0px;
    background-color: rgb(235, 235, 235);
    width: 30px;
    aspect-ratio: 1/1;
    line-height: 30px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .25s;
    opacity: 0;
    font-size: 15px;
    color: #333;
    cursor: pointer;
    z-index: 10;

}

.songcard-wrapper:hover .add-button {
    bottom: 50px;
    opacity: 1;
}



/**/


.songcard-wrapper.compact {
    min-height: 80px;
    min-width: 300px;
    height: 80px;
    width: 300px;
    display: flex;
    flex-direction: row;
    padding: 15px;
    margin: 0 5px;
}

.songcard-wrapper.compact h6 {
    margin: 5px 0 0 0;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
}



.songcard-wrapper.compact .play-button {
    right: 0px;
    width: 30px;
    line-height: 32px;
    font-size: 18px;
}

.songcard-wrapper.compact:hover .play-button {
    bottom: 10px;
}



.songcard-wrapper.compact .add-button {
    right: 32px;
    width: 30px;
    line-height: 32px;
    font-size: 18px;

}

.songcard-wrapper.compact:hover .add-button {
    bottom: 10px;
}
</style>
  